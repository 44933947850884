<template>
  <v-autocomplete
    v-model="customer"
    :items="items"
    :item-text="itemName"
    :item-value="computedItemValue"
    :loading="loading"
    :search-input.sync="search"
    :name="name"
    :label="label"
    :hint="hint"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :dense="dense"
    prepend-icon="mdi-account-search"
    @input="emitInput($event)"
    no-filter
    return-object
  >
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-if="chip"
        v-bind="attr"
        v-on="on"
        :input-value="selected"
        :color="itemColor"
        :large="chipLarge"
        :x-large="chipXLarge"
        :small="chipSmall"
        :x-small="chipXSmall"
        :outlined="chipOutlined"
        :disabled="disabled"
      >
        <span>
          {{ item.name + " #" + item.number }}
        </span>
      </v-chip>
      <span v-else :class="itemColor + '--text'">
        {{ item.name + " #" + item.number }}
      </span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ "#" + item.number }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="!hidePartnerType" class="body-2">
        {{ $t(item.partner_type) }} ({{ item.partner_type }})
      </v-list-item-action>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-if="search">
          {{ $t("customer with {search} not found", { search: search }) }}
        </v-list-item-title>
        <v-list-item-title v-else>
          {{ $t("enter customer name or number") }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
const isInteger = (num) => /^-?[0-9]+$/.test(num + "");

export default {
  name: "CustomerAutocomplete",
  props: {
    value: {
      required: true,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: "customer",
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    chip: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipXSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipXLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipOutlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemColor: {
      type: String,
      required: false,
      default: "primary",
    },
    itemDisabled: {
      type: String,
      required: false,
      default: "disabled",
    },
    itemValue: {
      type: String,
      validator: (value) => {
        if (value == null) return true;
        return ["number", "id"].includes(value);
      },
      required: false,
      default: null,
    },
    itemName: {
      type: String,
      required: false,
      default: "name",
    },
    returnValue: {
      type: String,
      required: false,
      validator: (value) => {
        if (value == null) return true;
        return ["object", "number", "id"].includes(value);
      },
      default: "object",
    },
    queryParams: {
      type: Object,
      required: false,
      default: () => {
        return {
          rated: true,
          unfiltered: true,
          extended: true,
          limit: 20,
        };
      },
    },
    hidePartnerType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      search: "",
      customer: null,
      identifier: null,
      loading: false,
      delayTimer: null,
      isInitial: true,
    };
  },
  computed: {
    computedItemValue() {
      return this.itemValue || "id";
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        var that = this;
        if (value != null) {
          let identifier = this.selectIdentifierByValue(value);
          if (
            this.isInitial &&
            identifier != null &&
            String(identifier) != this.search
          ) {
            clearTimeout(that.delayTimer);
            that.delayTimer = setTimeout(function () {
              that.identifier = identifier;
              that.search = String(identifier);
            }, 100);
          }
        } else {
          this.isInitial = false;
        }
      },
    },
    search(value) {
      var that = this;
      if ((value || "").trim().length >= 3) {
        clearTimeout(that.delayTimer);
        that.delayTimer = setTimeout(function () {
          that.searchCustomer();
        }, 500);
      }
    },
  },
  methods: {
    selectIdentifierByValue(value) {
      if (value == null) {
        return null;
      } else if (typeof value === "object") {
        if (this.itemValue != null && value[this.itemValue] != null)
          return value[this.itemValue];
        else if (this.itemValue == null && value["id"] != null) return value.id;
        else return null;
      } else if (typeof value === "string" || typeof value === "number") {
        return value;
      } else {
        return null;
      }
    },
    autoSelect() {
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        let identifier = this.selectIdentifierByValue(item);
        if (
          identifier != null &&
          this.identifier != null &&
          identifier == this.identifier
        ) {
          this.customer = item;
          break;
        }
      }
    },
    searchCustomer() {
      this.loading = true;
      var that = this;
      var q = (this.search || "").trim();
      var isNumber = isInteger(q);
      var retrieve =
        this.isInitial && isNumber && this.computedItemValue == "id";
      var url = "customers";
      var params = { ...this.queryParams, q: q };
      if (retrieve) {
        url = "customers/" + this.search;
        params = {};
      } else if (this.isInitial && this.computedItemValue == "number")
        params = {
          ...params,
          ignore_name: true,
          ignore_attr: true,
        };
      this.$http
        .get(url, { params: params })
        .then((response) => {
          if (retrieve) that.items = [response.data];
          else that.items = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          if (that.isInitial) that.autoSelect();
          that.loading = false;
          that.isInitial = false;
        });
    },
    emitInput(item) {
      this.search = null;
      if (item == null) this.$emit("input", null);
      else if (this.returnValue == "object") {
        this.$emit("input", { ...item });
      } else {
        this.$emit("input", item[this.computedItemValue]);
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "customer with {search} not found": "customer with {search} not found",
    "enter customer name or number": "enter customer name or number",
    "END": "End customer",
    "PAR": "Partner",
    "WHO": "Wholesale"
  }, 
  "de": {
    "customer with {search} not found": "Kunde mit {search} nicht gefunden",
    "enter customer name or number": "geben Sie einen Kundennamen oder eine Nummer ein",
    "END": "Endkunde",
    "PAR": "Partner",
    "WHO": "Grosskunde"
  }
}
</i18n>
