<template>
  <v-dialog
    v-model="dialog"
    :persistent="persistent"
    :transition="transition"
    :fullscreen="fullscreen"
    :hide-overlay="hideOverlay"
    :max-width="maxWidth"
    @click:outside="cancel"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :color="color"
        :disabled="disabled"
        :text="text"
        :loading="loading"
      >
        <slot></slot>
      </v-btn>
    </template>
    <v-card>
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="message">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="cancel-btn">
          <v-btn
            :color="cancelColor"
            :disabled="cancelDisabled"
            :text="cancelText"
            @click="cancel"
          >
            {{ cancelBtnText }}
          </v-btn>
        </slot>
        <slot name="confirm-btn">
          <v-btn
            :color="confirmColor"
            :disabled="confirmDisabled"
            :text="confirmText"
            @click="confirm"
          >
            {{ confirmBtnText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmBtn",
  props: {
    color: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    confirmColor: {
      type: String,
      default: "primary",
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: Boolean,
      default: false,
    },
    cancelColor: {
      type: String,
      default: null,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    confirmBtnText: {
      type: String,
      default: "ok",
    },
    cancelBtnText: {
      type: String,
      default: "cancel",
    },
    maxWidth: {
      type: [String, Number],
      default: 290,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: null,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    confirm() {
      this.dialog = false;
      this.$emit("confirm");
    },
    cancel() {
      this.dialog = false;
      this.$emit("cancel");
    },
  },
};
</script>
