<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              showCreateMessage = true;
              expanded = [];
            "
            :disabled="
              loadingCreate || showCreateMessage || !hasChangePermissions
            "
            :loading="loadingCreate"
            icon
          >
            <v-icon>mdi-message-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("create new messages") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="getMessages"
            :disabled="loading"
            :loading="loading"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh messages") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-subtitle v-if="showCreateMessage" class="mb-10">
      <message-details
        :value="{
          id: null,
          title: null,
          text: null,
          is_visible: true,
          is_incoming: false,
          client: null,
          scope: [],
          documents: [],
        }"
        :has-change-permissions="hasChangePermissions"
        @cancel="showCreateMessage = false"
        @input="
          showCreateMessage = false;
          getMessages();
        "
      />
    </v-card-subtitle>
    <v-card-subtitle>
      <v-row align="baseline" justify="center">
        <v-col cols="2">
          <v-text-field
            v-model="queryString"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            :label="$t('search')"
            @click:append-outer="getMessages()"
            @click:clear="
              queryString = null;
              getMessages();
            "
            @keyup.enter.native="getMessages()"
            single-line
            hide-details
          />
        </v-col>
        <v-col cols="2">
          <v-select
            :items="scopes"
            :label="$t('scope')"
            v-model="queryScopes"
            item-text="name"
            item-value="slug"
            dense
            :clearable="!isMobile"
            @change="getMessages"
            multiple
            :menu-props="{
              closeOnClick: true,
              closeOnContentClick: true,
            }"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    :style="{
                      color: scopeColors[item.slug],
                    }"
                  >
                    {{ scopeNames[item.slug] }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip
                :style="{ color: scopeColors[item.slug] }"
                outlined
                close
                @click:close="queryScopes.splice(index, 1)"
              >
                {{ scopeNames[item.slug] }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4">
          <customer-autocomplete
            name="client"
            v-model="queryClient"
            :placeholder="$t('customer name or number')"
            item-value="number"
            return-value="number"
            :query-params="{
              rated: true,
              unfiltered: true,
              extended: true,
              limit: 20,
            }"
            :clearable="!isMobile"
            @input="
              options.page = 1;
              getMessages();
            "
          />
        </v-col>
        <v-col cols="2">
          <v-select
            :items="daysAgoChoices"
            :label="$t('publish date')"
            v-model="daysAgo"
            item-text="text"
            item-value="value"
            dense
            :clearable="!isMobile"
            @change="getMessages"
          />
        </v-col>
        <v-col cols="2">
          <v-switch
            :label="$t('incoming')"
            v-model="incoming"
            dense
            :clearable="!isMobile"
            @change="getMessages"
          />
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="messages"
        :options.sync="options"
        :server-items-length="messagesTotal"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :expanded.sync="expanded"
        show-expand
        single-expand
        sort-by="publish_date"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        local-storage-name="messagesList"
        open-expand-by-click-row
      >
        <template v-slot:item.title="{ item }">
          {{ item.title }}
        </template>

        <template v-slot:item.created="{ item }">
          <div class="monospace text-no-wrap">
            {{ item.created ? formatDateTime(item.created) : "-" }}
          </div>
        </template>

        <template v-slot:item.publish_date="{ item }">
          <div
            :class="{
              monospace: true,
              'text-no-wrap': true,
              'primary--text':
                item.publish_date &&
                $moment(item.publish_date).isAfter($moment()),
            }"
          >
            {{ item.publish_date ? formatDateTime(item.publish_date) : "-" }}
          </div>
        </template>

        <template v-slot:item.unpublish_date="{ item }">
          <div
            :class="{
              monospace: true,
              'text-no-wrap': true,
              'primary--text':
                item.unpublish_date &&
                $moment(item.unpublish_date).isBefore($moment()),
            }"
          >
            {{
              item.unpublish_date ? formatDateTime(item.unpublish_date) : "-"
            }}
          </div>
        </template>

        <template v-slot:item.visible="{ item }">
          <boolean-value v-model="item.is_visible" />
        </template>

        <template v-slot:item.incoming="{ item }">
          <boolean-value
            v-model="item.is_incoming"
            :color="item.is_incoming ? 'primary' : ''"
          />
        </template>

        <template v-slot:item.status="{ item }">
          <v-icon dense color="primary" v-if="item.is_new">
            mdi-new-box
          </v-icon>
          <v-icon dense color="primary" v-if="item.client != null">
            mdi-star
          </v-icon>
          <v-icon dense color="primary" v-if="item.documents.length > 0">
            mdi-download
          </v-icon>
        </template>

        <template v-slot:item.client="{ item }">
          <v-chip name="client" v-if="item.client" outlined>
            {{ item.client.name }}
          </v-chip>
          <span v-else>-</span>
        </template>

        <template v-slot:item.scope="{ item }">
          <v-chip
            v-for="(scope, idx) in item.scope"
            :style="{ color: scopeColors[scope] }"
            outlined
            :key="idx"
            class="ml-1 mb-1"
          >
            {{ scopeNames[scope] }}
          </v-chip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <message-details
              :value="item"
              :has-change-permissions="hasChangePermissions"
              class="my-8 mx-4"
              @cancel="expanded = []"
              @input="getMessages()"
              @deleted="
                expanded = [];
                getMessages();
              "
              :key="item.id"
            />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";
import messageTools from "@/utils/mixins/messageTools";
import MessageDetails from "@/components/staff/MessageDetails";
import CustomerAutocomplete from "@/components/basics/CustomerAutocomplete";

export default {
  name: "StaffMessages",
  props: {
    changePermissionGroup: {
      type: String,
      default: null,
    },
  },
  mixins: [formatDateTime, isMobile, messageTools],
  components: {
    DataTableExtended,
    BooleanValue,
    MessageDetails,
    CustomerAutocomplete,
  },
  data: () => ({
    messages: [],
    queryString: null,
    queryScopes: [],
    queryClient: null,
    daysAgo: null,
    incoming: null,
    loading: false,
    options: {},
    expanded: [],
    messagesTotal: 0,
    options: {
      itemsPerPage: 15,
      page: 1,
    },
    showCreateMessage: false,
    loadingCreate: false,
    delayTimer: null,
  }),
  watch: {
    options: {
      handler() {
        this.getMessages();
      },
      deep: true,
      immediate: true,
    },
    expanded(value) {
      if (value != null && value.length > 0) this.showCreateMessage = false;
    },
    queryScopes() {
      this.getMessages();
    },
    daysAgo() {
      this.getMessages();
    },
  },
  computed: {
    changePermissionGroupReal() {
      return (
        this.changePermissionGroup || this.$config.msgChangePermissionGroup
      );
    },
    dates() {
      return [
        {
          text: this.$i18n.t("since 1 day"),
          value: this.dateToString(this.sinceDays(1)),
        },
        {
          text: this.$i18n.t("since 1 week"),
          value: this.dateToString(this.sinceDays(7)),
        },
        {
          text: this.$i18n.t("since 1 month"),
          value: this.dateToString(this.sinceMonths(1)),
        },
        {
          text: this.$i18n.t("since 3 months"),
          value: this.dateToString(this.sinceMonths(3)),
        },
        {
          text: this.$i18n.t("since 6 months"),
          value: this.dateToString(this.sinceMonths(6)),
        },
        {
          text: this.$i18n.t("since 1 year"),
          value: this.dateToString(this.sinceMonths(12)),
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$i18n.t("status"),
          value: "status",
          sortable: false,
        },
        {
          text: this.$i18n.t("title"),
          value: "title",
        },
        {
          text: this.$i18n.t("created date"),
          align: "right",
          value: "created",
          hidden: true,
        },
        {
          text: this.$i18n.t("publish date"),
          align: "right",
          value: "publish_date",
        },
        {
          text: this.$i18n.t("unpublish date"),
          align: "right",
          value: "unpublish_date",
        },
        {
          text: this.$i18n.t("visible"),
          value: "visible",
          sortable: false,
        },
        {
          text: this.$i18n.t("incoming"),
          value: "incoming",
          sortable: false,
        },
        {
          text: this.$i18n.t("client"),
          value: "client",
          sortable: false,
        },
        {
          text: this.$i18n.t("scope"),
          value: "scope",
          sortable: false,
        },
        { text: "", value: "data-table-expand", sortable: false },
      ];
    },
    daysAgoChoices() {
      return [
        {
          text: this.$i18n.t("since 1 day"),
          value: 1,
        },
        {
          text: this.$i18n.t("since 1 week"),
          value: 7,
        },
        {
          text: this.$i18n.t("since 1 month"),
          value: 31,
        },
        {
          text: this.$i18n.t("since 3 months"),
          value: 92,
        },
        {
          text: this.$i18n.t("since 6 months"),
          value: 183,
        },
        {
          text: this.$i18n.t("since 1 year"),
          value: 365,
        },
      ];
    },
    hasChangePermissions() {
      return this.$store.getters.staffGroups.includes(
        this.changePermissionGroupReal
      );
    },
  },
  methods: {
    getMessages() {
      var that = this;
      this.loading = true;
      this.$http
        .get("messages", {
          params: {
            q: this.queryString,
            scope: this.queryScopes,
            days_ago: this.daysAgo,
            client: this.queryClient,
            is_incoming: this.incoming ? true : null,
            page_number: this.options.page,
            page_size: this.options.itemsPerPage,
          },
        })
        .then((response) => {
          that.messages = response.data.results;
          that.pages = response.data.pages;
          that.messagesTotal = response.data.count;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "create new messages": "create new messages",
    "refresh messages": "refresh messages",
    "customer name or number": "customer name or number",
    "status": "Status",
    "created date": "Create date",
    "publish date": "Publish date",
    "unpublish date": "Unpublish date",
    "visible": "Visible",
    "client": "Customer",
    "scope": "Scope",
    "since 1 day": "Since today",
    "since 1 week": "Since this week",
    "since 1 month": "Since this month",
    "since 3 months": "Since this year",
    "since 6 months": "Since this year",
    "since 1 year": "Since last year",
    "incoming": "Incoming"
  },
  "de": {
    "create new messages": "neue Mitteilung anlegen",
    "refresh messages": "Mitteilungen aktualisieren",
    "customer name or number": "Kundenname oder Nummer",
    "status": "Status",
    "created date": "Anlegedatum",
    "publish date": "Veröffentlichsdatum",
    "unpublish date": "Unveröffentlichsdatum",
    "visible": "Sichtbar",
    "client": "Kunde",
    "scope": "Bereich",
    "since 1 day": "seit 1 Tag",
    "since 1 week": "seit 1 Woche",
    "since 1 month": "seit 1 Monat",
    "since 3 months": "seit 3 Monaten",
    "since 6 months": "seit 6 Monaten",
    "since 1 year": "seit einem Jahr",
    "incoming": "eingehend"
  }
}
</i18n>
