/*
Message tool mixins:

* `scopes`        - array of scope objects
* `loadingScopes` - bool is true when scopes being loaded
* `scopesLoaded`  - bool is true when scope loading is done
* `scopeNames`    - object with slug to name (current local) mapping
* `scopeColors`   - object with slug to color mapping

*/
export default {
  data: () => ({
    loadingScopes: false,
  }),
  computed: {
    scopes() {
      return this.$store.state.messageScopes;
    },
    scopesLoaded() {
      return this.$store.state.messageScopesLoaded;
    },
    scopeLocale() {
      return this.$store.state.locale;
    },
    scopeColors() {
      var scopeColors = {};
      for (let i = 0; i < this.scopes.length; i++) {
        scopeColors[this.scopes[i].slug] = this.scopes[i].color;
      }
      return scopeColors;
    },
    scopeNames() {
      var scopeNames = {};
      for (let i = 0; i < this.scopes.length; i++) {
        var scope = this.scopes[i];
        scopeNames[scope.slug] = scope['name_' + this.scopeLocale] || scope.name;
      }
      return scopeNames;
    }
  },
  watch: {
    scopesLoaded: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.$store.commit("setMessageScopesLoaded", true);
          this.getScopes();
        }
      },
    },
  },
  methods: {
    getScopes() {
      var that = this;
      this.loadingScopes = true;
      this.$http
        .get("messages/scopes")
        .then((response) => {
          that.$store.commit("setMessageScopes", response.data);
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingScopes = false;
        });
    },
  },
};
