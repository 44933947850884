var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loadingCreate || _vm.showCreateMessage || !_vm.hasChangePermissions,"loading":_vm.loadingCreate,"icon":""},on:{"click":function($event){_vm.showCreateMessage = true;
            _vm.expanded = [];}}},on),[_c('v-icon',[_vm._v("mdi-message-plus-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("create new messages")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"loading":_vm.loading,"icon":""},on:{"click":_vm.getMessages}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("refresh messages")))])])],1),(_vm.showCreateMessage)?_c('v-card-subtitle',{staticClass:"mb-10"},[_c('message-details',{attrs:{"value":{
        id: null,
        title: null,
        text: null,
        is_visible: true,
        is_incoming: false,
        client: null,
        scope: [],
        documents: [],
      },"has-change-permissions":_vm.hasChangePermissions},on:{"cancel":function($event){_vm.showCreateMessage = false},"input":function($event){_vm.showCreateMessage = false;
        _vm.getMessages();}}})],1):_vm._e(),_c('v-card-subtitle',[_c('v-row',{attrs:{"align":"baseline","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":!_vm.isMobile,"label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"click:append-outer":function($event){return _vm.getMessages()},"click:clear":function($event){_vm.queryString = null;
            _vm.getMessages();}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getMessages()}},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.scopes,"label":_vm.$t('scope'),"item-text":"name","item-value":"slug","dense":"","clearable":!_vm.isMobile,"multiple":"","menu-props":{
            closeOnClick: true,
            closeOnContentClick: true,
          }},on:{"change":_vm.getMessages},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{style:({
                    color: _vm.scopeColors[item.slug],
                  })},[_vm._v(" "+_vm._s(_vm.scopeNames[item.slug])+" ")])])],1)]}},{key:"selection",fn:function({ item, index }){return [_c('v-chip',{style:({ color: _vm.scopeColors[item.slug] }),attrs:{"outlined":"","close":""},on:{"click:close":function($event){return _vm.queryScopes.splice(index, 1)}}},[_vm._v(" "+_vm._s(_vm.scopeNames[item.slug])+" ")])]}}]),model:{value:(_vm.queryScopes),callback:function ($$v) {_vm.queryScopes=$$v},expression:"queryScopes"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('customer-autocomplete',{attrs:{"name":"client","placeholder":_vm.$t('customer name or number'),"item-value":"number","return-value":"number","query-params":{
            rated: true,
            unfiltered: true,
            extended: true,
            limit: 20,
          },"clearable":!_vm.isMobile},on:{"input":function($event){_vm.options.page = 1;
            _vm.getMessages();}},model:{value:(_vm.queryClient),callback:function ($$v) {_vm.queryClient=$$v},expression:"queryClient"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.daysAgoChoices,"label":_vm.$t('publish date'),"item-text":"text","item-value":"value","dense":"","clearable":!_vm.isMobile},on:{"change":_vm.getMessages},model:{value:(_vm.daysAgo),callback:function ($$v) {_vm.daysAgo=$$v},expression:"daysAgo"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":_vm.$t('incoming'),"dense":"","clearable":!_vm.isMobile},on:{"change":_vm.getMessages},model:{value:(_vm.incoming),callback:function ($$v) {_vm.incoming=$$v},expression:"incoming"}})],1)],1)],1),_c('v-card-text',[_c('data-table-extended',{attrs:{"headers":_vm.headers,"items":_vm.messages,"options":_vm.options,"server-items-length":_vm.messagesTotal,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"expanded":_vm.expanded,"show-expand":"","single-expand":"","sort-by":"publish_date","footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      },"local-storage-name":"messagesList","open-expand-by-click-row":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.created",fn:function({ item }){return [_c('div',{staticClass:"monospace text-no-wrap"},[_vm._v(" "+_vm._s(item.created ? _vm.formatDateTime(item.created) : "-")+" ")])]}},{key:"item.publish_date",fn:function({ item }){return [_c('div',{class:{
            monospace: true,
            'text-no-wrap': true,
            'primary--text':
              item.publish_date &&
              _vm.$moment(item.publish_date).isAfter(_vm.$moment()),
          }},[_vm._v(" "+_vm._s(item.publish_date ? _vm.formatDateTime(item.publish_date) : "-")+" ")])]}},{key:"item.unpublish_date",fn:function({ item }){return [_c('div',{class:{
            monospace: true,
            'text-no-wrap': true,
            'primary--text':
              item.unpublish_date &&
              _vm.$moment(item.unpublish_date).isBefore(_vm.$moment()),
          }},[_vm._v(" "+_vm._s(item.unpublish_date ? _vm.formatDateTime(item.unpublish_date) : "-")+" ")])]}},{key:"item.visible",fn:function({ item }){return [_c('boolean-value',{model:{value:(item.is_visible),callback:function ($$v) {_vm.$set(item, "is_visible", $$v)},expression:"item.is_visible"}})]}},{key:"item.incoming",fn:function({ item }){return [_c('boolean-value',{attrs:{"color":item.is_incoming ? 'primary' : ''},model:{value:(item.is_incoming),callback:function ($$v) {_vm.$set(item, "is_incoming", $$v)},expression:"item.is_incoming"}})]}},{key:"item.status",fn:function({ item }){return [(item.is_new)?_c('v-icon',{attrs:{"dense":"","color":"primary"}},[_vm._v(" mdi-new-box ")]):_vm._e(),(item.client != null)?_c('v-icon',{attrs:{"dense":"","color":"primary"}},[_vm._v(" mdi-star ")]):_vm._e(),(item.documents.length > 0)?_c('v-icon',{attrs:{"dense":"","color":"primary"}},[_vm._v(" mdi-download ")]):_vm._e()]}},{key:"item.client",fn:function({ item }){return [(item.client)?_c('v-chip',{attrs:{"name":"client","outlined":""}},[_vm._v(" "+_vm._s(item.client.name)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.scope",fn:function({ item }){return _vm._l((item.scope),function(scope,idx){return _c('v-chip',{key:idx,staticClass:"ml-1 mb-1",style:({ color: _vm.scopeColors[scope] }),attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.scopeNames[scope])+" ")])})}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('message-details',{key:item.id,staticClass:"my-8 mx-4",attrs:{"value":item,"has-change-permissions":_vm.hasChangePermissions},on:{"cancel":function($event){_vm.expanded = []},"input":function($event){return _vm.getMessages()},"deleted":function($event){_vm.expanded = [];
              _vm.getMessages();}}})],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }