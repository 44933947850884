<template>
  <v-card
    :loading="loading"
    :outlined="message.id == null"
    :flat="message.id != null"
  >
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <validation-provider
              vid="title"
              :name="$t('title')"
              rules="required|max:100"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="message.title"
                name="title"
                :label="$t('title')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                :disabled="message.is_incoming"
                counter="100"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card v-if="message.is_incoming" outlined>
              <v-card-text v-html="message.text" />
            </v-card>
            <validation-provider
              v-else
              vid="text"
              :name="$t('text')"
              rules="required"
            >
              <wysiwyg-editor v-model="message.text" :renderTables="false" />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <validation-provider
              vid="client"
              :name="$t('customer')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <customer-autocomplete
                name="client"
                v-model="message.client"
                :placeholder="$t('customer name or number')"
                item-value="number"
                :query-params="{
                  rated: true,
                  unfiltered: true,
                  extended: true,
                  limit: 20,
                }"
                :disabled="message.is_incoming"
                :clearable="!isMobile"
                chip
                item-color="primary"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              vid="is_incoming"
              :name="$t('is incoming')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-switch
                v-model="message.is_incoming"
                name="is_incoming"
                :label="$t('is incoming')"
                :error-messages="errors"
                :class="classes"
                disabled
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <validation-provider
              vid="scope"
              :name="$t('scope')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="message.scope"
                :items="scopes"
                item-value="slug"
                item-text="name"
                name="scope"
                :label="$t('scope')"
                :error-messages="errors"
                :class="classes"
                :disabled="message.is_incoming"
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                  disableKeys: false,
                }"
                hide-selected
                multiple
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        :style="{
                          color: scopeColors[item.slug],
                        }"
                      >
                        {{ scopeNames[item.slug] }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="body-2" v-if="item.is_public">
                    {{ $t("category") }}
                  </v-list-item-action>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    :style="{ color: scopeColors[item.slug] }"
                    outlined
                    close
                    @click:close="message.scope.splice(index, 1)"
                  >
                    {{ scopeNames[item.slug] }}
                  </v-chip>
                </template>
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              vid="is_visible"
              :name="$t('is visible')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-switch
                v-model="message.is_visible"
                name="is_visible"
                :label="$t('is visible')"
                :error-messages="errors"
                :class="classes"
                :disabled="message.is_incoming"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <validation-provider
              vid="publish_date"
              :name="$t('publish date')"
              :rules="publishTime != null ? 'required' : ''"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <date-picker
                v-model="publishDate"
                name="publish_date"
                :label="$t('publish date')"
                :error-messages="errors"
                :class="classes"
                :disabled="message.is_incoming"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
          <v-col cols="3">
            <validation-provider
              vid="publish_time"
              :name="$t('publish time')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <time-picker
                v-model="publishTime"
                name="publish_time"
                :label="$t('publish time')"
                :error-messages="errors"
                :class="classes"
                :disabled="message.is_incoming"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
          <v-col cols="3">
            <validation-provider
              vid="unpublish_date"
              :name="$t('unpublish date')"
              :rules="unpublishTime != null ? 'required' : ''"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <date-picker
                v-model="unpublishDate"
                name="unpublish_date"
                :label="$t('unpublish date')"
                :error-messages="errors"
                :class="classes"
                :disabled="message.is_incoming"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
          <v-col cols="3">
            <validation-provider
              vid="unpublish_time"
              :name="$t('unpublish time')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <time-picker
                v-model="unpublishTime"
                name="unpublish_time"
                :label="$t('unpublish time')"
                :error-messages="errors"
                :class="classes"
                :disabled="message.is_incoming"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-card class="pa-8 my-6" outlined>
          <v-row
            v-for="(doc, idx) in message.documents"
            :key="idx"
            align="center"
            justify="center"
            dense
          >
            <v-col cols="6">
              <v-text-field
                v-if="doc.id != null"
                v-model="doc.title"
                name="document.title"
                :label="$t('title')"
                disabled
              />
              <validation-provider
                v-else
                vid="document.title"
                :name="$t('title')"
                rules="max:200"
                v-slot="{ errors, classes }"
              >
                <v-text-field
                  v-model="doc.title"
                  name="document.title"
                  :label="$t('title')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="message.is_incoming"
                  :clearable="!isMobile"
                />
              </validation-provider>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-if="doc.id != null"
                v-model="doc.filename"
                disabled
              />
              <validation-provider
                v-else
                vid="document.content"
                :name="$t('file')"
                :rules="
                  (doc.title != null && doc.title != '' ? 'required|' : '') +
                  (doc.file != null && doc.file != ''
                    ? 'size:' + maxFileSize
                    : '')
                "
                v-slot="{ errors, classes }"
              >
                <v-file-input
                  v-model="doc.file"
                  name="document.content"
                  :label="$t('file')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="message.is_incoming"
                  :clearable="!isMobile"
                  show-size
                  @change="convertToBase64(doc, $event)"
                />
              </validation-provider>
            </v-col>
            <v-col cols="1">
              <v-tooltip
                bottom
                v-if="
                  doc.id != null ||
                  (doc.id == null && idx + 1 < message.documents.length)
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    :loading="doc.deleting"
                    :disabled="message.is_incoming"
                    @click="deleteDocument(doc, idx)"
                    icon
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("delete attachment") }}</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    :loading="doc.adding"
                    :disabled="message.is_incoming"
                    @click="appendDocument"
                    icon
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("add attachment") }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="doc.id != null">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :loading="doc.downloading"
                    @click="downloadDocument(doc)"
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("download attachment") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>

        <v-card-actions>
          <confirm-btn
            :disabled="
              deleting || loading || loadingDocuments || !hasChangePermissions
            "
            :loading="deleting"
            :title="$t('delete?')"
            :message="$t('deleteMsg')"
            :confirm-btn-text="$t('delete')"
            :cancel-btn-text="$t('cancel')"
            @confirm="deleteMessage"
            text
          >
            <v-icon left> mdi-delete </v-icon>
            {{ $t("delete") }}
          </confirm-btn>
          <v-spacer />
          <v-btn
            text
            @click="$emit('cancel')"
            :disabled="loading || loadingDocuments"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            v-if="message.id == null"
            color="primary"
            :disabled="
              invalid ||
              loading ||
              loadingDocuments ||
              message.is_incoming ||
              !hasChangePermissions
            "
            :loading="loading || loadingDocuments"
            @click="createMessage"
            text
          >
            {{ $t("create") }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="
              invalid ||
              loading ||
              loadingDocuments ||
              message.is_incoming ||
              !hasChangePermissions
            "
            :loading="loading || loadingDocuments"
            @click="updateMessage"
            text
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </validation-observer>
  </v-card>
</template>

<script>
import BooleanValue from "@/components/basics/BooleanValue.vue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";
import messageTools from "@/utils/mixins/messageTools";
import downloadFile from "@/utils/mixins/downloadFile";
import wysiwygEditor from "@/components/basics/wysiwygEditor";
import CustomerAutocomplete from "@/components/basics/CustomerAutocomplete";
import ConfirmBtn from "@/components/basics/ConfirmBtn";
import DatePicker from "@/components/basics/DatePicker";
import TimePicker from "@/components/basics/TimePicker";
import axios from "axios";

const emptyDocument = {
  id: null,
  title: null,
  filename: null,
  file: null,
};
const emptyMessage = {
  id: null,
  title: null,
  text: null,
  is_visible: true,
  publish_date: null,
  unpublish_date: null,
  client: { number: null },
  is_incoming: null,
  scope: [],
  documents: [{ ...emptyDocument }],
};

export default {
  name: "MessageDetails",
  components: {
    BooleanValue,
    CustomerAutocomplete,
    wysiwygEditor,
    DatePicker,
    TimePicker,
    ConfirmBtn,
  },
  props: {
    value: {
      type: Object,
      default() {
        return { ...emptyMessage };
      },
    },
    hasChangePermissions: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [formatDateTime, isMobile, messageTools, downloadFile],
  data: () => ({
    deleting: false,
    loading: false,
    loadingDocuments: false,
    message: {},
    maxFileSize: 100000, // 100 MB
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value != null) {
          this.message = { ...value };
          this.message.documents = [
            ...this.message.documents,
            { ...emptyDocument },
          ];
        } else {
          this.message = { ...emptyMessage };
        }
      },
    },
  },
  computed: {
    publishDate: {
      get() {
        if (this.message.publish_date)
          return this.$moment(this.message.publish_date).format("YYYY-MM-DD");
      },
      set(value) {
        if (value)
          this.message.publish_date = this.$moment(
            value + " " + (this.publishTime || "00:00:00")
          ).toISOString();
        else this.message.publish_date = value;
      },
    },
    publishTime: {
      get() {
        if (this.message.publish_date)
          return this.$moment(this.message.publish_date).format("HH:mm:ss");
      },
      set(value) {
        if (value)
          this.message.publish_date = this.$moment(
            (this.publishDate || this.$moment().format("YYYY-MM-DD")) +
              " " +
              value
          ).toISOString();
        else if (this.publishDate)
          this.message.publish_date = this.$moment(
            this.publishDate + " " + "00:00:00"
          ).toISOString();
        else this.message.publish_date = value;
      },
    },
    unpublishDate: {
      get() {
        if (this.message.unpublish_date)
          return this.$moment(this.message.unpublish_date).format("YYYY-MM-DD");
      },
      set(value) {
        if (value)
          this.message.unpublish_date = this.$moment(
            value + " " + (this.unpublishTime || "00:00:00")
          ).toISOString();
        else this.message.unpublish_date = value;
      },
    },
    unpublishTime: {
      get() {
        if (this.message.unpublish_date)
          return this.$moment(this.message.unpublish_date).format("HH:mm:ss");
      },
      set(value) {
        if (value)
          this.message.unpublish_date = this.$moment(
            (this.unpublishDate || this.$moment().format("YYYY-MM-DD")) +
              " " +
              value
          ).toISOString();
        else if (this.unpublishDate)
          this.message.unpublish_date = this.$moment(
            this.unpublishDate + " " + "00:00:00"
          ).toISOString();
        else this.message.unpublish_date = value;
      },
    },
  },
  methods: {
    convertToBase64(doc, file) {
      if (file != null) {
        doc.base64 = null;
        doc.filename = file.name;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", function (event) {
          doc.base64 = event.target.result;
        });
        fileReader.readAsDataURL(doc.file);
      }
    },
    updateMessage() {
      var that = this;
      var documents = this.message.documents;
      that.loading = true;
      this.$http
        .put("messages/" + this.message.id, {
          title: this.message.title,
          text: this.message.text,
          is_visible: this.message.is_visible,
          publish_date: this.message.publish_date,
          unpublish_date: this.message.unpublish_date,
          client:
            this.message.client && this.message.client.number
              ? this.message.client.number
              : null,
          scope: this.message.scope,
        })
        .then((response) => {
          that.message = response.data;
          that.syncDocuments(documents);
        })
        .catch((err) => {
          that.loadingDocuments = false;
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    createMessage() {
      var that = this;
      var documents = this.message.documents;
      that.loading = true;
      this.$http
        .post("messages", {
          title: this.message.title,
          text: this.message.text,
          is_visible: this.message.is_visible,
          publish_date: this.message.publish_date,
          unpublish_date: this.message.unpublish_date,
          client:
            this.message.client && this.message.client.number
              ? this.message.client.number
              : null,
          scope: this.message.scope,
        })
        .then((response) => {
          that.message = response.data;
          that.syncDocuments(documents);
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    deleteMessage() {
      var that = this;
      that.deleting = true;
      this.$http
        .delete("messages/" + this.message.id)
        .then((response) => {
          that.message = {};
          that.$emit("deleted");
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.deleting = false;
        });
    },
    syncDocuments(documents) {
      var that = this;
      var requests = [];
      for (let i = 0; i < documents.length; i++) {
        let doc = documents[i];
        if (doc.id == null && doc.file != null) {
          requests.push(
            this.$http.post("messages/" + this.message.id + "/documents", {
              title: doc.title,
              content: doc.base64 || null,
              filename: doc.filename,
            })
          );
        }
      }
      if (requests.length > 0) {
        this.loadingDocuments = true;
        axios
          .all(requests)
          .then((responseArray) => {
            that.$emit("input", { ...that.message });
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loadingDocuments = false;
          });
      } else that.$emit("input", { ...that.message });
    },
    deleteDocument(doc, idx) {
      var that = this;
      doc.deleting = true;
      if (doc.id == null) {
        this.message.documents.splice(idx, 1);
        this.message.documents = [...this.message.documents];
      } else
        this.$http
          .delete("messages/" + this.message.id + "/documents/" + doc.id)
          .then((response) => {
            that.$emit("input", { ...that.message });
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            doc.deleting = false;
          });
    },
    downloadDocument(doc) {
      this.downloadFile(
        "messages/" + this.message.id + "/documents/" + doc.id + "/download",
        "get",
        {},
        doc.filename
      );
    },
    appendDocument() {
      this.message.documents = [
        ...this.message.documents,
        { ...emptyDocument },
      ];
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Title",
    "text": "Text",
    "customer": "Customer",
    "customer name or number": "Customer name or number",
    "is incoming": "is incoming (sent by customer)",
    "publish date": "Publish date",
    "publish time": "Publish time",
    "unpublish date": "Unpublish date",
    "unpublish time": "Unpublish time",
    "is visible": "Is visible (for customers)",
    "scope": "Scope",
    "save": "Save",
    "create": "Create",
    "cancel": "Cancel",
    "delete": "Delete",
    "delete?": "Delete?",
    "file": "File",
    "category": "Category",
    "file size should be less than {maxFileSize}": "file size should be less than {maxFileSize}",
    "delete attachment": "delete attachment",
    "add attachment": "add attachment",
    "download attachment": "download attachment",
    "deleteMsg": "Are you sure you want to delete the message?"
  },
  "de": {
    "title": "Titel",
    "text": "Text",
    "customer": "Kunde",
    "customer name or number": "Kundenname order Nummer",
    "is incoming": "ist eingehend (gesendet vom Kunden)",
    "publish date": "Veröffentlichungsdatum",
    "publish time": "Veröffentlichungszeit",
    "unpublish date": "Unveröffentlichungsdatum",
    "unpublish time": "Unveröffentlichungszeit",
    "is visible": "Ist sichtbar (für Kunden)",
    "scope": "Bereich",
    "save": "Speichern",
    "create": "Anlegen",
    "cancel": "Abbrechen",
    "delete": "Löschen",
    "delete?": "Löschen?",
    "file": "Datei",
    "category": "Kategorie",
    "file size should be less than {maxFileSize}": "Dateigrösse sollte kleiner als {maxFileSize} sein",
    "delete attachment": "Anhang löschen",
    "add attachment": "Anhang hinzufügen",
    "download attachment": "Anhang herunterladen",
    "deleteMsg": "Sind Sie sicher, dass Sie die Mitteilung löschen wollen?"
  }
}
</i18n>
